import HttpService from './http.service';
import ConfigService from './config.service';

type AddressResult = {
  id: number,
  st_prefix: string | null,
  geometry?: {
    crs: {
      type: "name",
      properties: {
        name: "ESPG:4326"
      }
    },
  } & Point
} & AddressFeature["properties"];

type StatusResponse = {
  status: string;
}

class ApiService {

  public apiUrl: string;
  private http: typeof HttpService = HttpService;
  private config: typeof ConfigService = ConfigService;

  constructor() {
    const apiUrl = this.config.get('API_URL');
    this.apiUrl = apiUrl ?? '';
  }

  getStatus() {
    return this.http.get<StatusResponse>(`${this.apiUrl}/`).then((res) => {
      return res;
    });
  }

  // sends five coordinate pairs to the api
  getMapParcels(coordinates) {
    return this.http.get(`${this.apiUrl}/mapparcels/${coordinates}`).then((res) => {
      return res.response;
    });
  }

  // sends two coordinate pairs to api
  getMapParcelsFromBounds(minLng, minLat, maxLng, maxLat) {
    return this.http.get(`${this.apiUrl}/parcels/bounds?minLng=${minLng}&minLat=${minLat}&maxLng=${maxLng}&maxLat=${maxLat}`).then((res) => {
      return res.response;
    });
  }

  getAddressesFromBounds(minLng, minLat, maxLng, maxLat) {
    return this.http.get(`${this.apiUrl}/addresses/bounds?minLng=${minLng}&minLat=${minLat}&maxLng=${maxLng}&maxLat=${maxLat}`).then((res) => {
      return res.response;
    });
  }

  getParcelsFromPins(parcelPins: string[]) {
    parcelPins = parcelPins.map(function (pin) {
      return `parcelPin=${pin}`
    });

    let parcelPinsQuery = parcelPins.join('&');

    return this.http.get<ParcelFeatureCollection>(`${this.apiUrl}/parcels/pin?${parcelPinsQuery}`).then((res) => {
      return res.response;
    });
  }

  getAddressesFromPins(parcelPins: string[]) {
    parcelPins = parcelPins.map(function (pin) {
      return `parcelPin=${pin}`
    });

    let parcelPinsQuery = parcelPins.join('&');

    return this.http.get<AddressFeatureCollection>(`${this.apiUrl}/addresses/pin?${parcelPinsQuery}`).then((res) => {
      return res.response;
    });
  }

  getAddressesBySearch(search) {
    return this.http.get<AddressResult[]>(`${this.apiUrl}/addresses/search?q=${search}`).then((res) => {
      return res.response;
    });
  }

  getParcelRelatedInfo(parcelIds: string[]) {
    return this.http.get(`${this.apiUrl}/parcels/info?parcelPin=${parcelIds.join('&parcelPin=')}`);
  }
}

export default new ApiService();

export type FeatureCollection = ParcelFeatureCollection | AddressFeatureCollection;

export type ParcelFeatureCollection = {
  type: "FeatureCollection",
  features: ParcelFeature[]
}

export type AddressFeatureCollection = {
  type: "FeatureCollection",
  features: AddressFeature[]
}

export type AddressFeature = {
  type: "Feature",
  properties: {
    addr_num: string,
    st_name: string,
    st_type: string,
    municipality: string,
    state: string,
    zip_code: string,
    full_address: string,
  },
  geometry: Point,
}

export type Point = {
  type: "Point",
  coordinates: [Longitude, Latitude]
}

export type ParcelFeature = {
  type: "Feature",
  properties: {
    pin: string,
  },
  geometry: Polygon,
  
}

export type Polygon = {
  type: "Polygon",
  coordinates: [[[Longitude, Latitude]]]
}



export type Longitude = number;

export type Latitude = number;

