import { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import * as geojsonExtent from '@mapbox/geojson-extent' // yarn add @mapbox/geojson-extent - version should be 0.3.2 in the package.json file
import ConfigService from '../../../_services/config.service';
import ApiService from '../../../_services/api.service';
import { SidebarData} from '../Sidebar/Sidebar';


// NOTE: fix for mapbox-gl transpiling issue: https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-753662795
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = ConfigService.get('MAP_TOKEN');

type SourceName = 
  'all-parcels' |
  'all-addresses' |
  'linked-parcels' |
  'linked-addresses' |
  'selected-addresses' | 
  'selected-parcels';

type ShadingLayerId =
  'all-parcels-shading' |
  'linked-parcels-shading' |
  'selected-parcels-shading';

type LayoutVisibilityProperty = 'visible' | 'none';

type CircleLayerId = 
  'all-address-points' |
  'linked-address-points' |
  'selected-address-points';

type Coords = {
  lng: number,
  lat: number,
  zoom: number,
  setLng: React.Dispatch<React.SetStateAction<number>>,
  setLat: React.Dispatch<React.SetStateAction<number>>,
  setZoom: React.Dispatch<React.SetStateAction<number>>,
};

export function MapBox({
  map,
  parcelPins,
  setSidebarData, 
  previousLayer, 
  setButtonVisibility,
  coords,
}: {
  map: React.MutableRefObject<any>,
  // parcelPins comes from the query params of the url
  parcelPins: string[] | null,
  setSidebarData: React.Dispatch<React.SetStateAction<SidebarData>>,
  previousLayer: React.MutableRefObject<string>,
  setButtonVisibility: React.Dispatch<React.SetStateAction<boolean>>
  coords: Coords,
}) {
  const mapContainer = useRef<HTMLDivElement>(null);
  const {lng, lat, zoom, setLng, setLat, setZoom} = coords;

  // Initialize map
  useEffect(() => {
    if (map.current) return; // initialize map only once
    
    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom,
      });
    } catch (e) {
      console.error('Error initializing map', e);
    }
  });

  useEffect(() => {
    if (!map.current) return;

    const mapMoveEffect = mapOnMoveClosure({
      setLng,
      setLat,
      setZoom,
      map,
    });

    map.current.on('move', mapMoveEffect);

    // if params are null, we load all parcels/addresses in the current viewport, otherwise we load based on params and make all parcels invisible
    const mapLoadEvent = () => {
      
      if (parcelPins.length) {
        // we want to load specific parcel pins
        
        // loadData -> loadDataForViewport
        previousLayer.current = 'all-parcels';
        arrangeLayers({map});
        loadDataFromPins({map, parcelPins, setSidebarData, previousLayer, setButtonVisibility});

        loadData({map});  // loads all parcels
        map.current.on('moveend', () => {
          loadData({map});
        });
        arrangeLayers({map});
      } else {
        // we want to load for the viewport
        
        loadData({map});
        previousLayer.current = 'all-parcels';

        arrangeLayers({map});
        map.current.on('moveend', () => {
          loadData({map});
        });
      }
    };
    
    map.current.on('load', mapLoadEvent);

    

    // When a click event occurs on a feature, fill the sidebar with corresponding information
    const mapClickEvent = mapOnClickClosure({
      map,
      parcelPins,
      setSidebarData,
      previousLayer,
    });
    map.current.on('click', mapClickEvent);

    const mouseMoveEvent = function () {
      map.current.getCanvas().style.cursor = 'pointer';
    };

    // Change the cursor to a pointer when the mouse is over the parcels layer
    map.current.on('mousemove', 'all-parcels-shading', mouseMoveEvent);
    map.current.on('mousemove', 'linked-parcels-shading', mouseMoveEvent);
    map.current.on('mousemove', 'selected-parcels-shading',mouseMoveEvent);


    const mouseLeaveEvent = function () {
      map.current.getCanvas().style.cursor = '';
    };

    // Change it back to a pointer when it leaves
    map.current.on('mouseleave', 'all-parcels-shading', mouseLeaveEvent);
    map.current.on('mouseleave', 'linked-parcels-shading', mouseLeaveEvent);
    map.current.on('mouseleave', 'selected-parcels-shading', mouseLeaveEvent);

    return () => {
      // remove all event handlers from map on useEffect teardown
      map.current.off('move', mapMoveEffect);
      map.current.off('click', mapClickEvent);
      map.current.off('load', mapLoadEvent);

      map.current.off('mousemove', 'all-parcels-shading', mouseMoveEvent);
      map.current.off('mousemove', 'linked-parcels-shading', mouseMoveEvent);
      map.current.off('mousemove', 'selected-parcels-shading',mouseMoveEvent);
      
      map.current.off('mouseleave', 'all-parcels-shading', mouseLeaveEvent);
      map.current.off('mouseleave', 'linked-parcels-shading', mouseLeaveEvent);
      map.current.off('mouseleave', 'selected-parcels-shading', mouseLeaveEvent);
    }
  }, [map, parcelPins, setSidebarData, previousLayer, setButtonVisibility, setLat, setLng, setZoom]);


  return (
    <div ref={mapContainer} className="map-container" />
  );
}

// load data from viewport
function loadData({
  map,
  visibility = 'visible',
}: {
  map: React.MutableRefObject<any>,
  visibility?: LayoutVisibilityProperty,
}) {
  let bounds = map.current.getBounds();  // gets location data of SW and NE corners of map
  

  // this function accesses the api and sends four coordinates (min/max lng/lat) to create the bounding box in which to retrieve parcel data
  ApiService.getMapParcelsFromBounds(bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat).then(function (parcels) {
    let sourceName : SourceName = 'all-parcels';
    let shadingLayerId: ShadingLayerId = 'all-parcels-shading';
    loadParcels({map, parcels, sourceName, shadingLayerId, visibility});
  });

  ApiService.getAddressesFromBounds(bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat).then(function (addresses) {
    let sourceName: SourceName = 'all-addresses';
    let circleLayerId: CircleLayerId = 'all-address-points';
    loadAddresses({map, addresses, sourceName, circleLayerId, visibility});
  });
}

function loadParcels({
  map, 
  parcels, 
  sourceName, 
  shadingLayerId, 
  visibility = 'visible'
}: {
  map: React.MutableRefObject<any>,
  parcels: unknown,
  sourceName: SourceName,
  shadingLayerId: ShadingLayerId,
  visibility?: LayoutVisibilityProperty,
}) {
  if (map.current.getSource(sourceName)) {  // if the source already exists, update its data
    map.current.getSource(sourceName).setData(parcels);
  }
  else {
    // adds a single data source of geojson data to map containing all of the parcels returned from the api query
    map.current.addSource(sourceName, {
      'type': 'geojson',
      'data': parcels
    });
    // adds a layer of shading to visualize the parcel
    if (shadingLayerId === 'all-parcels-shading') {
      map.current.addLayer({
        'id': shadingLayerId,
        'type': 'fill',
        'source': sourceName, //references the data source
        'layout': {
          'visibility': visibility,
        },
        'paint': {
          'fill-color': 'transparent',
          'fill-opacity': 1,
          'fill-outline-color': '#000',
        },
      });
    } else {
      map.current.addLayer({
        'id': shadingLayerId,
        'type': 'fill',
        'source': sourceName, //references the data source
        'layout': {
          'visibility': visibility,
        },
        'paint': {
          'fill-color': '#8CD3FF',
          'fill-opacity': 0.5,
        },
      });
    }

  }
}


function loadAddresses({
  map, 
  addresses, 
  sourceName, 
  circleLayerId, 
  visibility = 'visible'
}:{
  map: React.MutableRefObject<any>,
  addresses: unknown,
  sourceName: SourceName,
  circleLayerId: CircleLayerId,
  visibility?: LayoutVisibilityProperty,
}) {
  if (map.current.getSource(sourceName)) {
    map.current.getSource(sourceName).setData(addresses);
  }
  else {
    // adds a single data source of geojson data to map containing all addresses returned from the api query
    map.current.addSource(sourceName, {
      "type": "geojson",
      "data": addresses
    });

    // Active layers get active colors
    let circleColor = '#c90076';

    // The base layer gets a basic color
    if (circleLayerId === 'all-address-points') {
      circleColor = '#000';
    }

    // adds a layer to visualize the addresses
    map.current.addLayer({
      'id': circleLayerId,
      'type': 'circle',
      'source': sourceName,
      'layout': {
        'visibility': visibility
      },
      'paint': {
        'circle-radius': 5,
        'circle-color': circleColor
      }
    });
  }
}

// this function loads parcels based on Parcel ID passed in the URL parameters, and then loads any addresses contained in those parcels
function loadDataFromPins({
  map, 
  parcelPins,
  setSidebarData,
  previousLayer,
  setButtonVisibility
}:{ 
  map: React.MutableRefObject<any>, 
  parcelPins: string[],
  setSidebarData: React.Dispatch<React.SetStateAction<SidebarData>>,
  previousLayer: React.MutableRefObject<string>,
  setButtonVisibility: React.Dispatch<React.SetStateAction<boolean>>,
}) {
  ApiService.getParcelsFromPins(parcelPins).then(function (parcels) {
    let sourceName: SourceName = 'linked-parcels';
    let shadingLayerId: ShadingLayerId = 'linked-parcels-shading';
    loadParcels({map, parcels, sourceName, shadingLayerId });

    previousLayer.current = 'linked-parcels';

    map.current.fitBounds(geojsonExtent(parcels), { padding: 25, maxZoom: 19 });  // extends viewport of the map to fit all parcels rendered on screen
  });

  ApiService.getAddressesFromPins(parcelPins).then(function (addressResults) {
    let sourceName: SourceName = 'linked-addresses';
    let circleLayerId: CircleLayerId = 'linked-address-points';
    const addresses = addressResults.features.map((result) => {
      return result.properties;
    });
    setSidebarData({parcels: parcelPins, addresses});
    loadAddresses({map, addresses: addressResults, sourceName, circleLayerId});
  });

  setButtonVisibility(true);  // turn on the button since we have linked parcels
};

function arrangeLayers({ 
  map, 
  parcelLayerToWaitFor = 'all-parcels-shading', 
  addressLayerToWaitFor = 'all-address-points'
}: {
  map: React.MutableRefObject<any>,
  parcelLayerToWaitFor?: ShadingLayerId,
  addressLayerToWaitFor?: CircleLayerId,
}) {
  // wait for all-parcels-shading to load - this source takes the longest - then adjust the other layers
  if (!map.current.getLayer(parcelLayerToWaitFor) && (!map.current.getLayer(addressLayerToWaitFor))) {
    setTimeout(() => {
      
      arrangeLayers({
        map,
        parcelLayerToWaitFor,
        addressLayerToWaitFor,
      });
    }, 200);
  } else {  // moveLayer moves it to the top, so we don't need to call it on all-parcels-shading
    map.current.moveLayer('all-address-points');
    if (map.current.getLayer('linked-parcels-shading')) {
      map.current.moveLayer('linked-parcels-shading');

      if (map.current.getLayer('linked-address-points')) {
        map.current.moveLayer('linked-address-points');
      }
    }
    if (map.current.getLayer('selected-parcels-shading')) {
      map.current.moveLayer('selected-parcels-shading');

      if (map.current.getLayer('selected-address-points')) {
        map.current.moveLayer('selected-address-points');
      }
    }
  }
};


function mapOnMoveClosure({
  setLng,
  setLat,
  setZoom,
  map,
}:{
  setLng: React.Dispatch<React.SetStateAction<number>>,
  setLat: React.Dispatch<React.SetStateAction<number>>,
  setZoom: React.Dispatch<React.SetStateAction<number>>,
  map: React.MutableRefObject<any>,
}) {
  
  return function mapOnMove() {
    setLng(map.current.getCenter().lng.toFixed(4));
    setLat(map.current.getCenter().lat.toFixed(4));
    setZoom(map.current.getZoom().toFixed(2));
  }
}


function mapOnClickClosure({
  map,
  parcelPins,
  setSidebarData,
  previousLayer
}: {
  map: React.MutableRefObject<any>,
  parcelPins: string[],
  setSidebarData: React.Dispatch<React.SetStateAction<SidebarData>>,
  previousLayer: React.MutableRefObject<string>,
}) {
  
  return function mapOnClick (e) {
    var features = map.current.queryRenderedFeatures(e.point);

    var addressList = [];
    var parcelList = [];

    features.forEach(feature => {
      if (
        feature.layer.id === 'all-address-points' || 
        feature.layer.id === 'linked-address-points' || 
        feature.layer.id === 'selected-address-points'
      ) {
        addressList.push(feature.properties);
      }
      else if (
        feature.layer.id === 'all-parcels-shading' || 
        feature.layer.id === 'linked-parcels-shading' || 
        feature.layer.id === 'selected-parcels-shading'
      ) {
        parcelList.push(feature.properties.pin);
      }
    });

    
    if (
      // throws an error if layer doesn't exist, so we always check if it exists first
      map.current.getLayer('selected-parcels-shading') &&
      map.current.getLayoutProperty('selected-parcels-shading', 'visibility') === 'visible'
    ) {
        // clicking outside of a selected parcel takes user back to linked-parcels view
        if (addressList.length === 0 && parcelList.length === 0 && previousLayer.current === 'linked-parcels') {
          map.current.setLayoutProperty('linked-parcels-shading', 'visibility', 'visible');
          map.current.setLayoutProperty('linked-address-points', 'visibility', 'visible');

          if (map.current.getLayer('selected-parcels-shading')) {
            map.current.setLayoutProperty('selected-parcels-shading', 'visibility', 'none');
          }
          if (map.current.getLayer('selected-address-points')) {
            map.current.setLayoutProperty('selected-address-points', 'visibility', 'none');
          }

          setSidebarData({parcels: parcelPins})
        }
        // clicking outside of a selected parcel takes user back to all-parcels view
        else if (addressList.length === 0 && parcelList.length === 0 && previousLayer.current === 'all-parcels') {
          map.current.setLayoutProperty('all-parcels-shading', 'visibility', 'visible');
          map.current.setLayoutProperty('all-address-points', 'visibility', 'visible');

          if (map.current.getLayer('selected-parcels-shading')) {
            map.current.setLayoutProperty('selected-parcels-shading', 'visibility', 'none');
          }
          if (map.current.getLayer('selected-address-points')) {
            map.current.setLayoutProperty('selected-address-points', 'visibility', 'none');
          }

          setSidebarData({});
        } else {
          // we are on a selected parcel and clicking on a selected parcel, 
          // but possibly clicking on an address now
          setSidebarData({parcels: parcelList, addresses: addressList});
        }
      
    }



    if (map.current.getLayer('linked-parcels-shading')) {      
      if (map.current.getLayoutProperty('linked-parcels-shading', 'visibility') === 'visible') {
        
        if (addressList.length === 0 && parcelList.length === 0) {
          // if we're on the linked parcels layer, and a user clicked on no parcels, we should be showing all the parcels
        }
        else {
          setSidebarData({parcels: parcelList, addresses: addressList});

          ApiService.getParcelsFromPins(parcelList).then(function (parcels) {
            let sourceName: SourceName = 'selected-parcels';
            let shadingLayerId: ShadingLayerId = 'selected-parcels-shading';
            loadParcels({map, parcels, sourceName, shadingLayerId});
            
            // if the layer exists and the visibility is none, turn it on
            if (map.current.getLayoutProperty(shadingLayerId, 'visibility') === 'none') {
              map.current.setLayoutProperty(shadingLayerId, 'visibility', 'visible');
            }
            // turn off the visibility of non-selected parcels
            // map.current.setLayoutProperty('all-parcels-shading', 'visibility', 'none');

            map.current.setLayoutProperty('linked-parcels-shading', 'visibility', 'none');
          });

          ApiService.getAddressesFromPins(parcelList).then(function (addresses) {
            let sourceName: SourceName = 'selected-addresses';
            let circleLayerId: CircleLayerId = 'selected-address-points';
            loadAddresses({map, addresses, sourceName, circleLayerId});

            if (map.current.getLayoutProperty(circleLayerId, 'visibility') === 'none') {
              map.current.setLayoutProperty(circleLayerId, 'visibility', 'visible');
            }

            if (map.current.getLayer('linked-address-points')) {
              map.current.setLayoutProperty('linked-address-points', 'visibility', 'none');
            }
          });

          map.current.once(function () {
            let parcelLayerToWaitFor: ShadingLayerId = 'selected-parcels-shading';
            let addressLayerToWaitFor: CircleLayerId = 'selected-address-points';
            arrangeLayers({map, parcelLayerToWaitFor, addressLayerToWaitFor});
          });
        }
      }
    }




    if (map.current.getLayoutProperty('all-parcels-shading', 'visibility') === 'visible') {
      
      if (addressList.length === 0 && parcelList.length === 0) {
        // do nothing (for right now) - if all parcels are shown, when one is clicked, it will become selected and we will handle getting back to seeing all parcels when we click out of the selected parcel
      }
      else {
        
        // find the selected parcels, and if the visibility is none, make it visible
        if (parcelList.length) {
          setSidebarData({parcels: parcelList, addresses: addressList});

          ApiService.getParcelsFromPins(parcelList).then(function (parcels) {
            let sourceName: SourceName = 'selected-parcels';
            let shadingLayerId: ShadingLayerId = 'selected-parcels-shading';
            loadParcels({map, parcels, sourceName, shadingLayerId});
            // if the layer exists and the visibility is none, turn it on
            if (map.current.getLayoutProperty(shadingLayerId, 'visibility') === 'none') {
              map.current.setLayoutProperty(shadingLayerId, 'visibility', 'visible');
            }
            // turn off the visibility of linked parcels layer when selecting parcels
            if (map.current.getLayer('linked-parcels-shading')) {
              map.current.setLayoutProperty('linked-parcels-shading', 'visibility', 'none');
            }
          });  

          ApiService.getAddressesFromPins(parcelList).then(function (addresses) {
            let sourceName: SourceName = 'selected-addresses';
            let circleLayerId: CircleLayerId = 'selected-address-points';
            loadAddresses({map, addresses, sourceName, circleLayerId});
  
            if (map.current.getLayoutProperty(circleLayerId, 'visibility') === 'none') {
              map.current.setLayoutProperty(circleLayerId, 'visibility', 'visible');
            }
  
            if (map.current.getLayer('linked-address-points')) {
              map.current.setLayoutProperty('linked-address-points', 'visibility', 'none');
            }
          });
        }

        map.current.once(function () {
          let parcelLayerToWaitFor: ShadingLayerId = 'selected-parcels-shading';
          let addressLayerToWaitFor: CircleLayerId = 'selected-address-points';
          arrangeLayers({map, parcelLayerToWaitFor, addressLayerToWaitFor});
        });
      }
    }

  }
}