import ApiService from '../../../_services/api.service';

export type FormattedAddressSearchResult = {
  address: string,
  lat: number,
  lng: number,
};

export class Suggestions {
  private newestQuery: string = '';

  public suggest = (query, syncResults) => {
    this.newestQuery = query;
    this.apiCall(query).then(results => {
      if (query === this.newestQuery) {
        syncResults(query
          ? results
          : []
        );
      }
    });
  }



  private apiCall = async (value): Promise<Array<FormattedAddressSearchResult>> => {
    const results = await ApiService.getAddressesBySearch(value);
    if (!results) {
      return [];
    }
    const limited = results.slice(0, 100);
    const formatted = limited.map((a) => {
      return {
        address: `${a.full_address}, ${a.municipality}, ${a.state} ${a.zip_code}`,
        lat: a.geometry?.coordinates?.[1],
        lng: a.geometry?.coordinates?.[0],
      };
    });
    return formatted;
  }

}
