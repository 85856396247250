import ApiService from "../../../_services/api.service";
import { usePromiseLoader } from "../../../_services/usePromiseLoader";
import React from "react";


function ParcelDataComponent({parcels}:{parcels: string[]}) {
  const promise = ApiService.getParcelRelatedInfo(parcels);
  const {loading, data} = usePromiseLoader(promise, parcels);
  
  if (loading) {
    return (
      <p>Loading...</p>
    );
  }

  if (!(data as any)?.response) {
    return (
      <p>Issue loading data.</p>
    );
  }

  const parcelsData = (data as any).response;

  return (
    <>
    <h3>PARCEL IDs:</h3>
    <ol>
      {
        parcelsData.results.map(result => {
          return <li key={result.parcel_id}>
            <p>{result.parcel_id}</p>
            <p>{result.owner}</p>
          </li>
        })
      }
    </ol>
  </>
  );
  
}

export const ParcelData = React.memo(ParcelDataComponent);
