import { useState, useRef } from 'react';
import './Map.css';
import { InfoBox } from './InfoBox/InfoBox';
import { Sidebar, sidebarStates, SidebarData } from './Sidebar/Sidebar';
import { AccessibleSearchBox } from './AccessibleSearchBox/AccessibleSearchBox';
import { LayerToggle } from './LayerToggle/LayerToggle';
import { MapBox } from './MapBox/MapBox';

// TODO: Fix a way to click on address and see what it is

export function Map() {
  const queryParams = new URLSearchParams(window.location.search);
  const parcelPinsParam: string | null = queryParams.get('parcels');
  const parcelPins = parcelPinsParam ? parcelPinsParam.split(",") : [];
  const [sidebarState, setSidebarState] = useState<sidebarStates>("closed");
  const [sidebarData, setSidebarData] = useState<SidebarData>({parcels: undefined, addresses: undefined});
  const map = useRef(null);
  
  const [lng, setLng] = useState(-80.0196);
  const [lat, setLat] = useState(40.3931);
  const [zoom, setZoom] = useState(18.1);

  const coords = {
    lng,
    lat,
    zoom,
    setLng,
    setLat,
    setZoom,
  }

  // this needs to be a ref to be used in map.on('click') function due to a Mapbox issue: https://github.com/alex3165/react-mapbox-gl/issues/963\
  const previousLayer = useRef('');  // needed to turn visibility of layers on and off accordingly

  const [buttonVisibility, setButtonVisibility] = useState(false);  // controls whether or not the button is shown

  function toggleLayer(e) {  // this function handles the onClick of the button
    var clickedLayer = e.target.innerHTML;

    // this should not use innerHtml and text to decide which to do
    if (clickedLayer === 'All Parcels') {
      map.current.setLayoutProperty('all-parcels-shading', 'visibility', 'visible');
      map.current.setLayoutProperty('all-address-points', 'visibility', 'visible');

      // if there is a selected parcel showing, we must hide its visibility
      if (map.current.getLayer('selected-parcels-shading')) {
        if (map.current.getLayoutProperty('selected-parcels-shading', 'visibility') === 'visible') {
          map.current.setLayoutProperty('selected-parcels-shading', 'visibility', 'none');
          if (map.current.getLayoutProperty('selected-address-points', 'visibility') === 'visible') {
            map.current.setLayoutProperty('selected-address-points', 'visibility', 'none');
          }
        }
      }
      map.current.setLayoutProperty('linked-parcels-shading', 'visibility', 'none');
      map.current.setLayoutProperty('linked-address-points', 'visibility', 'none');

      previousLayer.current = 'all-parcels';
      setSidebarData({});
    }
    else if (clickedLayer === 'Linked Parcels') {
      map.current.setLayoutProperty('linked-parcels-shading', 'visibility', 'visible');
      map.current.setLayoutProperty('linked-address-points', 'visibility', 'visible');
      map.current.setLayoutProperty('all-parcels-shading', 'visibility', 'visible');
      map.current.setLayoutProperty('all-address-points', 'visibility', 'visible');

      // if there is a selected parcel showing, we must hide its visibility
      if (map.current.getLayer('selected-parcels-shading')) {
        if (map.current.getLayoutProperty('selected-parcels-shading', 'visibility') === 'visible') {
          map.current.setLayoutProperty('selected-parcels-shading', 'visibility', 'none');
          if (map.current.getLayoutProperty('selected-address-points', 'visibility') === 'visible') {
            map.current.setLayoutProperty('selected-address-points', 'visibility', 'none');
          }
        }
      }

      previousLayer.current = 'linked-parcels';
      // When we set to linked parcels, we need to reset the search bar to display linked parcels.
      setSidebarData({parcels: parcelPins});
    }
  };


  function handleLatLng({lat, lng}) {
    
    setLng(lng);
    setLat(lat);
    map.current.flyTo({
      center: [lng, lat],
      essential: true,
    });
  }

  return (
    <>
      <InfoBox {...{lat,lng,zoom}} />
      <MapBox {...{map, parcelPins, setSidebarState, setSidebarData, previousLayer, setButtonVisibility, coords}} />
      <Sidebar {...{sidebarState, setSidebarState, sidebarData}}></Sidebar>
      <LayerToggle visible={buttonVisibility} toggleLayer={toggleLayer}></LayerToggle>
      <AccessibleSearchBox onChangeLatLng={handleLatLng}></AccessibleSearchBox>
    </>
  );
}

