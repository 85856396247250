import './Sidebar.css';
import {AddressFeature} from '../../../_services/api.service';
import { ParcelData } from './ParcelData';

export type sidebarStates = "open" | "closed";

export type SidebarData = {
  parcels?: string[],
  addresses?: AddressFeature["properties"][]
}

export function Sidebar({ 
  sidebarState, 
  sidebarData, 
  setSidebarState
}: {
  sidebarState: sidebarStates,
  sidebarData: SidebarData,
  setSidebarState: React.Dispatch<React.SetStateAction<sidebarStates>>
}) {    

    const toggleSidebar = () => {
      const newState = sidebarState === 'closed' ? 'open' : 'closed';
      setSidebarState(newState);
    }

    const hasParcels = !!(sidebarData.parcels?.length);
    const hasAddresses = !!(sidebarData.addresses?.length);
    let address = <></>;
    if (hasAddresses) {
      const a = sidebarData?.addresses[0];
      address = <>{a.full_address}<br/>{a.municipality}, {a.state}<br/> {a.zip_code}</>;
    }
  
    return (
      <div className={`map-sidebar ${sidebarState}`}>
        <div className="map-sidebar--content">
        {(!hasParcels && !hasAddresses) && (
          <p>
            Please select a parcel to view more information about it.
          </p>
        )}
        {(hasParcels || hasAddresses) && (
          <>
          {hasAddresses && (
            <>
              <h3>ADDRESS:</h3>
              <p>
                { address }
              </p>
            </>
          )}
          {hasParcels && (
            <ParcelData parcels={sidebarData.parcels}></ParcelData>
          )}  
          </>
        )}
        </div>
        <button className="map-sidebar--toggle" onClick={toggleSidebar}>
          toggle
        </button>
      </div>
    )
  }
