import { MouseEventHandler } from 'react';
import './LayerToggle.css';

export function LayerToggle({ visible, toggleLayer }: {
  visible: boolean,
  toggleLayer: MouseEventHandler<HTMLButtonElement>,
  
}) {
  return visible 
  && (<div className='layer-toggle'>
        <button className="layer-toggle--all" id='all-parcels' onClick={toggleLayer}>All Parcels</button>
        <button className="layer-toggle--linked" id='parcel-layer-toggle' onClick={toggleLayer}>Linked Parcels</button>
      </div>);
}