import { useState, useEffect } from 'react';
import './App.css';
import ApiService from './_services/api.service';

import { Map } from './_pages/map/Map';

export function App() {
  const loadingStatus = {
    status: -1,
    response: {
      status: 'pending'
    }
  };

  const [status, setStatus] = useState(loadingStatus);

  useEffect(() => {
    ApiService.getStatus()
      .then((res) => setStatus(res))
      .catch((err) => setStatus(err));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <a className="App-name" href="/">Parcels and Addresses</a>
        <dl className="App-info">
          <dt className="App-status">Status</dt>
          <dl className="App-status">{status.status}: {status.response?.status || 'Not up'}</dl>
        </dl>
      </header>
      <main className="App-main">
        <Map />
      </main>
    </div>
  );
}
