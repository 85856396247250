import Autocomplete from 'accessible-autocomplete/react';
import './AccessibleSearchBox.css';
import { Suggestions } from './Suggestions';

export function AccessibleSearchBox({
  onChangeLatLng
}) {
  function handleConfirmed(confirmed) {
    if (!confirmed?.lng || !confirmed?.lat) {
      return;
    }
    onChangeLatLng({
      lng: confirmed?.lng,
      lat: confirmed?.lat,
    })
  }

  const suggestions = new Suggestions();

  return (
    <div className="searchBox">
      <label htmlFor="my-autocomplete">Type and select an address</label>
      <Autocomplete id='my-autocomplete' source={suggestions.suggest} templates={formatTemplates()} onConfirm={handleConfirmed} placeholder={"Type and select an address"}/>
    </div>
  );
}

export function formatTemplates() {
  return {
    inputValue: formatInputValue,
    suggestion: formatSuggestion,
  }
}

export function formatInputValue(input) {
  return input?.address;
}

export function formatSuggestion(input) {
  return input?.address;
}
